import { onBeforeMount, onServerPrefetch } from 'vue'
import { isClient, isServer } from '/@/app/environment'
import { useGlobalState } from '/@/app/state'

export const useUniversalFetch = (fetcher: () => Promise<any>) => {
  const globalState = useGlobalState()

  // Server side: SSR
  if (isServer) {
    onServerPrefetch(() => {
      return fetcher().catch((error) => {
        // HACK 因为 onServerPrefetch 或者 async setup 都无法中断 renderToString, 所以需要在状态被抛出前就做一个标记
        globalState.setRenderError(error)
        return Promise.reject(error)
      })
    })
  }

  // SSR: client
  if (isClient) {
    // After the hydration first screen, all client actions, such as navigating to a new page, require a data request.
    if (globalState.isHydrated.value) {
      onBeforeMount(() => fetcher())
    } else {
      // Hydration: nothing needs to be done, the store data is initialized with the SSR context
      // onServerPreFetch: https://github.com/vuejs/composition-api/pull/198/files
      // isHydrating: https://github.com/vuejs/vue-next/issues/1723
      // isHydrating: https://github.com/vuejs/vue-next/pull/2016
    }
  }
}
