<template>
  <div class="sponsor-provider" :class="activeProvider.id">
    <p class="external" v-if="activeProvider.text">
      <template v-if="activeProvider.text">
        {{ activeProvider.text }}
        <i class="iconfont icon-qrcode"></i>
      </template>
    </p>
    <uimage class="qrcode" v-if="activeProvider.qrcode" :src="activeProvider.qrcode" cdn />
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue'
  import { SponsorState } from '/@/components/widget/sponsor/state'

  interface Props {
    state: SponsorState
    maxSponsors: number
  }

  const props = withDefaults(defineProps<Props>(), {
    maxSponsors: 19
  })

  const activeProvider = computed(() => props.state.activeProvider.value)
</script>
<style lang="scss" scoped>
  .sponsor-provider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 18rem;

    .external {
      margin-bottom: $gap;
      color: $text-secondary;

      .link {
        margin-left: $sm-gap;
        cursor: pointer;
        text-decoration: underline;
        color: $text-secondary;
        &:hover {
          color: $link-color;
        }
      }

      .iconfont {
        font-size: $font-size-small;
        color: $text-secondary;
        margin-left: $xs-gap;
      }
    }

    .qrcode {
      height: 14rem;
      max-height: 60%;
      border-radius: $xs-radius;
    }

    .github-sponsors {
      text-align: center;

      .link {
        display: inline-block;
        padding: 0 1em;
        line-height: 3em;
        border-radius: $sm-radius;
        background-color: $module-bg-darker-2;
        transition: background-color $transition-time-fast;
        &:hover {
          background-color: $module-bg-darker-3;
          .text {
            color: $link-color;
          }
        }

        .iconfont {
          margin-right: $sm-gap;
          font-size: $font-size-h4;
          color: $github-sponsor-primary;
        }

        .text {
          color: $text;
          font-weight: bold;
        }
      }

      .sponsor-box {
        margin-top: 2rem;
        margin-bottom: $sm-gap;

        .total {
          margin-bottom: 2rem;
          font-weight: bold;
          .active-total {
            color: $github-sponsor-primary;
          }
        }

        .sponsors {
          display: grid;
          grid-template-columns: repeat(10, 1fr);
          grid-column-gap: $sm-gap;
          grid-row-gap: $gap;

          .item {
            display: flex;
            flex-direction: column;
            &.active {
              .avatar {
                padding: 1px;
                outline: 1px solid $github-sponsor-primary;
                opacity: 0.8;
              }
              &:hover {
                .avatar {
                  opacity: 1;
                }
              }
            }
            &.inactive {
              .avatar {
                filter: grayscale(1);
                opacity: 0.7;
              }
              &:hover {
                .avatar {
                  filter: grayscale(0);
                  opacity: 1;
                }
              }
            }

            .avatar {
              width: 3rem;
              height: 3rem;
              border-radius: 100%;
              overflow: hidden;
              background-color: $module-bg-darker-1;
              transition: opacity $transition-time-fast;
            }
          }

          .more-link {
            margin-left: $sm-gap;
            display: inline-flex;
            align-items: center;
            font-weight: bold;
            font-size: $font-size-h4;
            color: $text-secondary;
            &:hover {
              color: $link-color;
            }
          }
        }
      }
    }
  }
</style>
