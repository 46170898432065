<template>
  <div class="friendlink">
    <div class="header">
      <span class="title">
        <i class="iconfont icon-user" />
        <span class="text">
          {{ _i18n.t(LanguageKey.FRIEND_LINK) }}
        </span>
      </span>
    </div>
    <placeholder
      :data="appOption.data?.friend_links"
      :loading="appOption.fetching"
      :i18n-key="LanguageKey.ARTICLE_PLACEHOLDER"
    >
      <template #loading>
        <ul class="article-list-skeleton" key="skeleton">
          <li v-for="item in 5" :key="item" class="item">
            <SkeletonLine />
          </li>
        </ul>
      </template>
      <template #default>
        <div class="friendlink-list" key="list">
          <a v-for="(link, index) in appOption.data?.friend_links" :key="index" class="item" :href="link.value">
            <span class="name">
              {{ link.name }}
            </span>
          </a>
        </div>
      </template>
    </placeholder>
  </div>
</template>

<script setup lang="ts">
  import { useStores } from '/@/stores'
  import { LanguageKey } from '/@/language'
  import SkeletonLine from '/@/components/common/skeleton/line.vue'
  import { useEnhancer } from '/@/app/enhancer'
  const { i18n: _i18n } = useEnhancer()
  const { appOption } = useStores()
  appOption.fetch()
</script>

<style lang="scss" scoped>
  .friendlink {
    margin-bottom: 0;
    overflow-y: auto;
    width: 100%;
    border-top: $gap solid transparent;
    border-bottom: $gap solid transparent;
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 3em;
      line-height: 3em;
      margin: 0;
      padding: 0 $gap;
      border-bottom: 1px dotted $module-bg-darker-1;

      .title {
        .text {
          font-weight: bold;
        }
        .iconfont {
          margin-right: $sm-gap;
        }
      }

      .types {
        .item {
          font-size: $font-size-base - 1;
          color: $text-secondary;
          cursor: pointer;
          &.activated {
            font-weight: 600;
          }
          &.activated,
          &:hover {
            color: $text;
          }
        }
      }
    }

    .article-list-skeleton {
      list-style: none;
      padding: $gap;
      margin: 0;

      > .item {
        height: 1em;
        margin-bottom: $gap;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .friendlink-list {
      list-style: none;
      padding: 0;
      overflow: hidden;
      margin-left: $gap;
      // margin-top: $gap;

      .item {
        $height: 2em;
        display: inline-flex;
        margin-right: $sm-gap;
        margin-top: $gap;
        height: $height;
        line-height: $height;
        font-size: $font-size-h6;
        font-family: $font-family-normal;
        @include radius-box($xs-radius);

        .iconfont {
          width: 2em;
          text-align: center;
          background-color: $module-bg-darker-1;
          @include background-transition();
        }

        .name {
          display: block;
          padding: 0 $sm-gap;
          @include background-transition();

          .count {
            margin-left: $xs-gap;
            color: $text-secondary;
          }
        }

        &.link-active {
          .iconfont {
            color: $module-bg-opaque;
            background-color: $primary-translucent;
          }
          .name {
            font-weight: bold;
            color: $text-reversal;
            background-color: $primary;
            .count {
              color: $text-reversal;
            }
          }
        }

        &:not(.link-active) {
          .name {
            background-color: $module-bg-hover;
          }

          &:hover {
            .iconfont {
              background-color: $module-bg-hover;
            }
            .name {
              background-color: $module-bg-darker-3;
            }
          }
        }
      }
    }
  }
</style>
