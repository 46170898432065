<template>
  <StatisticBase brand="skill" :fetching="fetching" :platform="isZhLang ? '技能' : 'Skill'">
    <p class="line-1">
      <i class="iconfont icon-package"></i>
      <i18n>
        <template #zh>
          <span class="title">语言</span>
        </template>
        <template #en>
          <span class="title">Language</span>
        </template>
      </i18n>
      <img
        decoding="async"
        src="https://img.shields.io/badge/-JavaScript-F7DF1E?style=flat&logo=JavaScript&logoColor=white"
        alt="JavaScript"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-TypeScript-3178C6?style=flat&logo=TypeScript&logoColor=white"
        alt="TypeScript"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Node.js-339933?style=flat&logo=Node.js&logoColor=white"
        alt="Node.js"
      />
    </p>
    <p>
      <i class="iconfont icon-download"></i>
      <i18n>
        <template #zh>
          <span class="title">前端</span>
        </template>
        <template #en>
          <span class="title">FrontEnd</span>
        </template>
      </i18n>
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Vue.js-4FC08D?style=flat&logo=Vue.js&logoColor=white"
        alt="Vue.js"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-React.js-61dafb?style=flat&logo=React&logoColor=white"
        alt="React.js"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Nuxt.js-00C58E?style=flat&logo=Nuxt.js&logoColor=white"
        alt="Nuxt.js"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Sass-CC6699?style=flat&logo=Sass&logoColor=white"
        alt="Sass"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Less-1D365D?style=flat&logo=Less&logoColor=white"
        alt="Less"
      />
    </p>
    <p>
      <i class="iconfont icon-score"></i>
      <i18n>
        <template #zh>
          <span class="title">后端</span>
        </template>
        <template #en>
          <span class="title">BackEnd</span>
        </template>
      </i18n>
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Mysql-4479A1?style=flat&logo=mysql&logoColor=white"
        alt="Mysql"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Redis-DC382D?style=flat&logo=redis&logoColor=white"
        alt="Redis"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-Nginx-269539?style=flat&logo=nginx&logoColor=white"
        alt="Nginx"
      />
      <img
        decoding="async"
        src="https://img.shields.io/badge/-MongoDB-green?style=flat&logo=MongoDB&logoColor=white"
        alt="Mongo"
      />
      <!-- https://img.shields.io/badge/MongoDB-v4.4-green?logo=mongodb -->
    </p>
  </StatisticBase>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import StatisticBase from './base.vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { delayer } from '/@/utils/delayer'

  const { isZhLang } = useEnhancer()
  const fetching = ref(true)

  const delay = delayer(688)
  delay(() => {
    fetching.value = false
  })
</script>

<style lang="scss" scoped>
  .line-1 {
    margin-top: $lg-gap;
    margin-bottom: 0.7em;
  }
  p {
    display: flex;
    align-items: center;
    i {
      vertical-align: -webkit-baseline-middle;
    }
    .title {
      width: 65px;
      display: inline-block;
    }
    img {
      margin-right: 4px;
    }
  }
</style>
