import { computed, watch } from 'vue'
import { createWebHistory } from 'vue-router'
import { createMainApp } from './app/main'
import lozad from '/@/composables/lozad'
// import { createDefer } from '/@/composables/defer'
import { LayoutColumn } from './app/state'
import { getClientLocalTheme } from './composables/theme'
import { resetTitler, runTitler } from './effects/titler'
import { initCopyrighter } from '/@/effects/copyright'
import { exportAppToGlobal } from '/@/effects/exporter'
import { Language, LanguageKey } from './language'
import { getLayoutByRouteMeta } from './transforms/layout'
import { createMusic } from '/@/composables/music'
import { createPopup } from '/@/composables/popup'
// import { isProd } from './environment'

import '/@/effects/swiper/style'
import '/@/styles/app.scss'
import { isProd } from './environment'
import { consoleSlogan } from './effects/slogan'

// app
const { app, router, head, globalState, i18n, store, getGlobalHead } = createMainApp({
  historyCreator: createWebHistory,
  language: navigator.language,
  userAgent: navigator.userAgent,
  layout: LayoutColumn.Normal,
  theme: getClientLocalTheme()
})

// services
// const defer = createDefer()
const popup = createPopup()
const music = createMusic({ delay: 668, continueNext: true })

// plugins & services
app.use(music)
app.use(lozad, { exportToGlobal: true })
app.use(popup, { exportToGlobal: true })

// init: store (from SSR context or fetch)
store.hydrate()

// init: services with client
// exportEmojiRainToGlobal()
// exportStickyEventsToGlobal()
exportAppToGlobal(app)
initCopyrighter()

// init global head
const globalHead = computed(() => getGlobalHead())
const mainHead = head.push(globalHead, { mode: 'client' })
watch(globalHead, (newValue) => mainHead.patch(newValue))

// router ready -> mount
router.isReady().finally(() => {
  // UI layout: set UI layout by route (for SPA)
  globalState.setLayoutColumn(getLayoutByRouteMeta(router.currentRoute.value.meta))
  // mount (force hydrate)
  app.mount('#app', true).$nextTick(() => {
    // set hydrate state
    globalState.setHydrate()
    // reset: i18n language
    i18n.set(globalState.userAgent.isZhUser ? Language.Chinese : Language.English)
    // init user identity state
    store.stores.identity.initOnClient()
    // title surprise
    document.addEventListener(
      'visibilitychange',
      (event) => {
        // @ts-ignore
        const isHidden = event.target?.hidden || event.target?.webkitHidden
        const surprises = [
          // { favicon: '🔞', title: 'FBI WARNING' },
          // { favicon: '⭕️', title: 'FBI WARNING' },
          // tltle: zero width character,
          { favicon: '🌱', title: '' }
        ]
        const index = Math.floor(Math.random() * surprises.length)
        isHidden ? runTitler(surprises[index]) : resetTitler()
      },
      false
    )
    // production only
    if (isProd) {
      consoleSlogan(i18n.t(LanguageKey.APP_SLOGAN)!, store.stores.appOption.data?.site_email)
    }
  })
})
