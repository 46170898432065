import { computed, ComputedGetter } from 'vue'
import { UseSeoMetaInput } from '@unhead/schema'
import { Head, useHead as useUnhead, useSeoMeta as useUnheadSeoMeta } from '@unhead/vue'
import { META } from '/@/config/app.config'
// import { useEnhancer } from '/@/app/enhancer'

export type { Head } from '@unhead/vue'
export { createHead } from '@unhead/vue'

export function useHead(source: Head | ComputedGetter<Head>) {
  return useUnhead(
    computed<Head>(() => {
      return typeof source === 'function' ? source() : source
    })
  )
}

const DEFAULT_TITLER = (title: string) => `${title} | ${META.title}`

export interface SeoMetaObject extends Omit<UseSeoMetaInput, 'title'> {
  title?: string
  pageTitle?: string
  description?: string
  keywords?: string
}

export function useSeoMeta(source: SeoMetaObject | ComputedGetter<SeoMetaObject>) {
  // const { i18n, route } = useEnhancer()
  const input = computed(() => {
    const value = typeof source === 'function' ? source() : source
    const { title, pageTitle, description, keywords, ...rest } = value

    const pureTitle = title ?? pageTitle
    const fullTitle = title ? title : pageTitle ? DEFAULT_TITLER(pageTitle) : ''
    return {
      pureTitle,
      fullTitle,
      description,
      keywords,
      _: rest,
    }
  })

  return useUnheadSeoMeta({
    title: computed(() => input.value.fullTitle),
    description: () => input.value.description ?? '',
    keywords: () => input.value.keywords ?? '',
  })
}
