import { computed, ref } from 'vue'

export enum ProviderId {
  Qq = 'qq',
  Alipay = 'alipay',
  WeChatPay = 'wechat-pay'
}

export const PROVIDER_IDS = Object.values(ProviderId)
export const PROVIDERS = [
  {
    id: ProviderId.Qq,
    title: 'QQ',
    text: '通过QQ客户端扫码',
    logo: '/images/third-party/qq-pay-logo.svg',
    qrcode: '/images/third-party/qq-pay-qrcode.png'
  },
  {
    id: ProviderId.WeChatPay,
    title: '微信',
    text: '通过微信客户端扫码',
    logo: '/images/third-party/wechat-pay-logo.svg',
    qrcode: '/images/third-party/wechat-pay-qrcode.png'
  },
  {
    id: ProviderId.Alipay,
    title: '支付宝',
    text: '通过支付宝客户端扫码',
    logo: '/images/third-party/alipay-logo.svg',
    qrcode: '/images/third-party/alipay-qrcode.jpg'
  }
]

export type SponsorState = ReturnType<typeof useSponsorState>
export const useSponsorState = (initId?: ProviderId) => {
  const activeId = ref(initId && PROVIDER_IDS.includes(initId) ? initId : PROVIDERS[0].id)
  const activeProvider = computed(() => PROVIDERS.find((p) => p.id === activeId.value)!)

  const setProviderId = (id: ProviderId) => {
    if (PROVIDER_IDS.includes(id)) {
      activeId.value = id
    }
  }

  return {
    activeId,
    activeProvider,
    setProviderId
  }
}
