export const get = (key: string) => localStorage.getItem(key)
export const set = (key: string, data: string) => localStorage.setItem(key, data)
export const remove = (key: string) => localStorage.removeItem(key)

export const setJSON = (key: string, data: unknown) => set(key, JSON.stringify(data))
export const getJSON = <T = unknown>(key: string): T | null => {
  const data = get(key)
  return typeof data === 'string' ? JSON.parse(data) : null
}

export default {
  get,
  set,
  remove,
  setJSON,
  getJSON,
}
