<template>
  <header :id="HEADER_ELEMENT_ID" class="header" :class="{ 'enable-nav': isEnabledNav }">
    <div class="header-container container">
      <div class="header-header">
        <uimage cdn src="/images/logo.png" class="header-logo" :alt="META.title" />
        <webfont class="header-slogan">
          <i18n :k="LanguageKey.APP_SLOGAN" />
        </webfont>
        <router-link to="/" class="header-link" :title="META.title" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { LanguageKey } from '/@/language'
  import { META } from '/@/config/app.config'
  import { HEADER_ELEMENT_ID } from '/@/constants/anchor'

  const { globalState } = useEnhancer()
  // 非常规布局
  const isEnabledNav = computed(() => !globalState.layoutColumn.value.isNormal)

  const handleLogin = () => {}
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    z-index: $z-index-header;
    background-color: $module-bg;
    border-bottom: 1px solid $module-bg-darker-2;
    @include backdrop-blur(5px);

    &.enable-nav:hover {
      .header-nav {
        @include visible();
      }
    }

    .header-container {
      height: 100%;
      display: flex;
      justify-content: space-between;

      .header-header {
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        padding-left: $sm-gap;
        width: 29em;
        overflow: hidden;

        @keyframes logo-blink {
          0% {
            mask-position: -30%;
          }
          100% {
            mask-position: 666%;
          }
        }

        .header-logo {
          width: 11rem;
          margin-right: $gap * 4;
          filter: $theme-logo-rotate;
          .logo-st {
            fill: $primary;
          }
        }

        .header-slogan {
          color: $primary;
          font-size: $font-size-h5;
        }

        .header-link {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }

    .header-nav {
      width: 100%;
      height: 4rem;
      background-color: $primary-lighter;
      @include hidden();
      @include visibility-transition();

      .nav-list {
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .divider {
          height: 6px;
          width: 1px;
          background-color: $module-bg-translucent;
        }

        .item {
          position: relative;
          display: inline-flex;
          align-items: center;
          color: $text-reversal;
          opacity: 0.7;
          @include visibility-transition();
          &:hover {
            opacity: 1;
          }
          &.link-active {
            .text {
              padding-top: 4px;
              padding-bottom: 2px;
              border-bottom: 2px solid;
            }
          }

          .font-icon {
            margin-right: $sm-gap;
          }

          .image-icon {
            width: 1em;
            height: 1em;
            margin-right: $sm-gap;
            border-radius: $xs-radius;
          }

          .superscript {
            margin-left: $xs-gap;
          }

          .new-window {
            margin-left: $xs-gap;
            margin-right: -$xs-gap;
            margin-top: -$sm-gap;
            font-size: $font-size-small - 3;
            opacity: 0.8;
          }
        }
      }
    }
  }
</style>
