<template>
  <StatisticBase brand="profile" :fetching="fetching" :platform="isZhLang ? '关于本站' : 'About'">
    <p class="line-1">
      <i class="iconfont icon-package"></i>
      <span>欢迎来到我的网站，让你笑到嘴角扯到耳朵！</span>
    </p>
    <p>
      <i class="iconfont icon-download"></i>
      <span>快来我的网站，开启一段充满惊喜的趣味之旅！</span>
    </p>
    <p>
      <i class="iconfont icon-score"></i>
      <span>在这里，乐趣无限，每一次点击都是一次欢乐的冒险！</span>
    </p>
  </StatisticBase>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import StatisticBase from './base.vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { delayer } from '/@/utils/delayer'

  const { isZhLang } = useEnhancer()
  const fetching = ref(true)

  const delay = delayer(688)
  delay(() => {
    fetching.value = false
  })
</script>

<style lang="scss" scoped>
  .line-1 {
    margin-top: $lg-gap;
    margin-bottom: 0.7em;
  }
</style>
