<template>
  <span
    class="webfont"
    :class="[bolder ? 'bolder' : 'medium', isZhLang ? 'zh' : 'en', { boldZh }, { boldEn }, { uppercase }]"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">
  import { useEnhancer } from '/@/app/enhancer'

  defineProps({
    bolder: {
      type: Boolean,
      default: false
    },
    boldZh: {
      type: Boolean,
      default: false
    },
    boldEn: {
      type: Boolean,
      default: true
    },
    uppercase: {
      type: Boolean,
      default: false
    }
  })

  const { isZhLang } = useEnhancer()
</script>

<style lang="scss" scoped>
  .webfont {
    &.zh {
      font-weight: normal;
      /* HACK: for Chinese font */
      margin-top: -1px;

      &.medium {
        font-family: 'webfont-medium';
      }
      &.bolder {
        font-family: 'webfont-bolder';
      }
      &.boldZh {
        font-weight: bold;
      }
    }

    &.en {
      font-size: 95%;
      font-family: inherit;
      &.boldEn {
        font-weight: bold;
      }
      &.uppercase {
        text-transform: uppercase;
      }
    }
  }
</style>
