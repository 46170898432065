import { createSSRApp } from 'vue'
import { RouterHistory } from 'vue-router'
import { Language, LanguageKey, languages } from '/@/language'
import { createUniversalStore } from '/@/stores'
import { createI18n } from '/@/composables/i18n'
import { createHead, Head } from '/@/composables/head'
import { createTheme, Theme } from '/@/composables/theme'
import { NODE_ENV } from '/@/environment'
import { createUniversalRouter, RouteCreatorOptions } from './router'
import { createGlobalState, LayoutColumn } from './state'
import App from './index.vue'
import components from './components'
import API_CONFIG from '/@/config/api.config'

console.group('🔵 [APP:INIT]')
console.table({ NODE_ENV })
console.groupEnd()

console.group('🔵 [APP:API]')
console.table(API_CONFIG)
console.groupEnd()

export interface ICreatorContext {
  historyCreator(base?: string): RouterHistory
  routerBeforeMiddleware?(globalState: any): RouteCreatorOptions['beforeMiddleWare']
  routerAfterMiddleware?(globalState: any): RouteCreatorOptions['afterMiddleware']
  layout?: LayoutColumn
  theme: Theme
  language: string
  userAgent: string
}

export type MainApp = ReturnType<typeof createMainApp>
export const createMainApp = (context: ICreatorContext) => {
  // 1. create app
  const app = createSSRApp(App)
  // 2. global state
  const globalState = createGlobalState({
    userAgent: context.userAgent || '',
    language: context.language || '',
    layout: context.layout ?? LayoutColumn.Normal
  })

  // 3. store
  const store = createUniversalStore({ globalState })

  // 4. router
  const router = createUniversalRouter({
    beforeMiddleWare: context.routerBeforeMiddleware?.(globalState),
    afterMiddleware: context.routerAfterMiddleware?.(globalState),
    history: context.historyCreator()
  })

  // 5. componsables
  const head = createHead()
  const theme = createTheme(context.theme)
  const i18n = createI18n({
    default: globalState.userAgent.isZhUser ? Language.Chinese : Language.English,
    keys: Object.values(LanguageKey),
    languages
  })

  // 全局head
  const getGlobalHead = (): Head => ({
    htmlAttrs: {
      lang: i18n.l.value?.iso ?? '',
      'data-theme': theme.theme.value,
      'data-device': globalState.userAgent.isMobile ? 'mobile' : 'desktop'
    }
  })

  app.config.errorHandler = (error) => globalState.setRenderError(error)
  // handle router error https://next.router.vuejs.org/api/#onerror
  router.onError(globalState.setRenderError)

  // handle router validate error & 404 error
  // https://next.router.vuejs.org/guide/advanced/navigation-guards.html#optional-third-argument-next
  // router.beforeEach((to, _, next) => {
  //   if (to.meta.validate) {
  //     ;(to.meta as any)
  //       .validate({ route: to, i18n, store })
  //       .then(next)
  //       .catch((error) => {
  //         const newError: any = new Error()
  //         newError.code = error.code
  //         newError.message = error.message
  //         next(newError)
  //       })
  //   } else {
  //     next()
  //   }
  // })

  app.use(router)
  app.use(store)
  app.use(globalState)
  app.use(i18n)
  app.use(head)
  app.use(theme)
  app.use(components)

  return {
    app,
    router,
    store,
    globalState,
    i18n,
    head,
    theme,
    getGlobalHead
  }
}
