import { isClient, isServer } from '/@/app/environment'
export * from './context'
export * from './prefetch'
export * from './hydration'

// env only
export const onClient = (callback: any) => {
  isClient && callback()
}

export const onServer = (calllback: any) => {
  isServer && calllback()
}
