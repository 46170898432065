import { defineStore } from 'pinia'
import { createFetchStore } from './_fetch'
import nodepress from '/@/services/nodepress'
export interface NodePressStatistic {
  tags: number
  articles: number
  comments: number
  todayViews: number
  totalViews: number
  totalLikes: number
  averageEmotion: number
}

export const useNodepressStatisticStore = defineStore('nodepressStatistic', () => {
  return createFetchStore<NodePressStatistic | null>({
    data: null,
    fetcher: async () => {
      const response = await nodepress.get<NodePressStatistic>('/expansion/statistic')
      return response.result
    },
  })
})
