import { enableCopyrighter } from '/@/effects/copyright'

export const read = () => navigator.clipboard.readText()
export const copy = (text: string) => {
  return window.navigator.clipboard.writeText(text).finally(() => {
    enableCopyrighter()
  })
}

export default { copy, read }
