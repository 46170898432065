<template>
  <div :id="FOOTER_ELEMENT_ID" class="footer">
    <div class="container">
      <div class="copy-right">CopyRight © Superficial Blog All Rights Reserved.</div>
      <div class="beian">
        <a href="/sitemap.xml" target="_blank">站点地图</a>
        <span> | </span>
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ appOption.data?.site_icp }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useStores } from '/@/stores'
  import { FOOTER_ELEMENT_ID } from '/@/constants/anchor'

  const { appOption } = useStores()
</script>

<style lang="scss" scoped>
  .footer {
    width: 100%;
    height: 6em;
    margin-top: 1em;
    padding: 1em 0;
    line-height: 2em;
    text-align: center;
    background-color: $module-bg;
    .links {
      width: 1260px;
      margin: 0 auto;
      font-size: 16px;
      .links-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .link {
          a {
            display: block;
            padding: 15px;
            color: $text;
          }
        }
      }
    }
    .copy-right {
      text-shadow: 0 -1px 0 #333;
      color: $text;
    }
    .beian {
      a {
        color: $text;
      }
    }
  }
</style>
