<template>
  <div class="guestbook-page">
    <Responsive>
      <template #desktop>
        <div class="desktop-banner">
          <uimage class="image" cdn :src="bannerImage"></uimage>
          <button class="like" :class="{ like: isLiked }" :disabled="isLiked" @click="handleLike">
            <i class="icon iconfont icon-heart"></i>
            <span class="count">{{ isLiked ? `${siteLikes - 1} + 1` : siteLikes }}</span>
          </button>
          <span class="slogan">
            <webfont class="text" :class="{ dark: isDarkTheme }">
              <i18n :k="LanguageKey.GUESTBOOK_SLOGAN" />
            </webfont>
          </span>
        </div>
      </template>
      <template #mobile>
        <PageBaner class="mobile-banner" :is-mobile="true" :position="70" :image="bannerImage">
          <template #title>
            <i18n :k="LanguageKey.PAGE_GUESTBOOK" />
          </template>
          <template #description>
            <i18n :k="LanguageKey.GUESTBOOK_SLOGAN" />
          </template>
        </PageBaner>
      </template>
    </Responsive>
    <div class="comment">
      <Comment :post-id="0" :plain="isMobile" :fetching="mockCommentLoading" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useStores } from '/@/stores'
  import { isClient } from '/@/app/environment'
  import { CommentPostId } from '/@/constants/state'
  import { Language, LanguageKey } from '/@/language'
  import { firstUpperCase } from '/@/transforms/text'
  import { META } from '/@/config/app.config'
  import { useUniversalFetch } from '/@/universal'
  import { Responsive } from '/@/components/common/responsive'
  import uimage from '/@/components/common/uimage'
  import webfont from '/@/components/common/webfont.vue'
  import Comment from '/@/components/comment/index.vue'
  import PageBaner from '/@/components/common/banner.vue'

  const GUESTBOOK_POST_ID = CommentPostId.Guestbook

  defineProps<{
    isMobile?: boolean
  }>()

  const { i18n: _i18n, seoMeta, globalState, isDarkTheme, isZhLang } = useEnhancer()
  const { appOption, comment, identity } = useStores()
  // MARK: [SSR & not first page] only
  const mockCommentLoading = ref(isClient && globalState.isHydrated.value)
  const isLiked = computed(() => identity.isLikedPage(GUESTBOOK_POST_ID))
  const siteLikes = computed(() => appOption.data?.meta.likes || 0)
  const bannerImage = '/images/page-guestbook/banner.jpg'

  const handleLike = async () => {
    if (isLiked.value) {
      return false
    }
    try {
      await appOption.postSiteLike()
      identity.likePage(GUESTBOOK_POST_ID)
    } catch (error) {
      const message = _i18n.t(LanguageKey.POST_ACTION_ERROR)
      console.warn(message, error)
      alert(message)
    }
  }

  const fetchAllData = () => {
    return Promise.all([appOption.fetch(true), comment.fetchList({ post_id: GUESTBOOK_POST_ID })]).then(() => {
      mockCommentLoading.value = false
    })
  }

  seoMeta(() => {
    const enTitle = firstUpperCase(_i18n.t(LanguageKey.PAGE_GUESTBOOK, Language.English)!)
    const titles = isZhLang.value ? [_i18n.t(LanguageKey.PAGE_GUESTBOOK), enTitle] : [enTitle]
    const description = isZhLang.value ? `给 ${META.author} 留言` : 'Leave a comment'
    return {
      pageTitle: titles.join(' | '),
      description
    }
  })

  useUniversalFetch(() => fetchAllData())
</script>

<style lang="scss" scoped>
  .guestbook-page {
    .mobile-banner {
      margin-bottom: $lg-gap;
    }

    .desktop-banner {
      position: relative;
      margin-bottom: $lg-gap;
      width: 100%;
      height: 19rem;
      border: 0;
      background-color: $module-bg;
      @include radius-box($lg-radius);

      .image {
        // margin-top: -($gap * 6);
        width: 100%;
        height: 100%;
        transition: all $transition-time-slow;
        &:hover {
          transform: rotate(2deg) scale(1.1);
        }
      }

      .like {
        position: absolute;
        left: $lg-gap * 2;
        bottom: $gap * 2;
        display: inline-flex;
        align-items: center;

        &.liked,
        &:hover {
          .icon {
            color: $red;
          }
        }

        .icon {
          margin-right: $sm-gap;
          color: rgba($red, 0.6);
          font-size: $font-size-h2;
          @include color-transition();
        }

        .count {
          color: rgba($white, 0.8);
          font-weight: bold;
        }
      }

      .slogan {
        $size: 2em;
        display: block;
        position: absolute;
        right: $lg-gap * 2;
        bottom: $gap * 2;
        height: $size;
        line-height: $size;
        padding: 0 $sm-gap;
        padding-left: 3rem;
        border-top-right-radius: $mini-radius;
        border-bottom-right-radius: $mini-radius;
        background: linear-gradient(to left, $module-bg-lighter, $module-bg, transparent);
        opacity: 0.8;
        cursor: none;

        .text {
          letter-spacing: 0.3px;
          color: $text;
          background-clip: text;
          background-image: url('/images/page-guestbook/banner.jpg');
          background-position: 100% 80%;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &.dark {
            -webkit-text-fill-color: $text !important;
          }
        }
      }
    }
  }
</style>
