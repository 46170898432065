<template>
  <div id="wallflower">
    <ul v-if="state.flowers.length" class="graden-box">
      <Flower
        v-for="(flower, index) in state.flowers"
        :key="flower.id"
        :z-index="index + 1"
        :options="flower"
        @ended="handleAnimationEnd"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
  import { reactive, onMounted, onBeforeMount } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { IFlower } from './helper'
  import Flower from './flower.vue'
  const EN_FLOWERS = ['💙', '🤍', '❤️']
  const ZH_FLOWERS = [
    '富强',
    '民主',
    '文明',
    '和谐',
    '自由',
    '平等',
    '公正',
    '法治',
    '爱国',
    '敬业',
    '诚信',
    '友善'
  ]

  const { isZhLang } = useEnhancer()
  const state = reactive({
    id: 0,
    flowers: [] as IFlower[],
    contextIndex: -1
  })

  const handleClick = (event: MouseEvent) => {
    const flowers = isZhLang.value ? ZH_FLOWERS : EN_FLOWERS
    state.contextIndex++
    if (state.contextIndex >= flowers.length) {
      state.contextIndex = 0
    }
    state.flowers.push({
      id: ++state.id,
      x: event.x || event.clientX,
      y: event.y || event.clientY,
      text: flowers[state.contextIndex]
    } as IFlower)
  }

  const handleAnimationEnd = (id: number) => {
    const targetIndex = state.flowers.findIndex((flower) => flower.id === id)
    if (targetIndex > -1) {
      state.flowers.splice(targetIndex, 1)
    }
  }

  onMounted(() => window.addEventListener('click', handleClick))
  onBeforeMount(() => window.removeEventListener('click', handleClick))
</script>

<style lang="scss" scoped>
  #wallflower {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    list-style: none;
    z-index: $z-index-underground;

    .garden-box {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
</style>
