<template>
  <ul class="comment-list" :class="isChildList ? 'child' : 'root'">
    <transition-group name="list-fade">
      <CommentItem
        v-for="item in comments"
        :key="item.comment.id"
        :comment="item.comment"
        :liked="identity.isLikedComment(item.comment.id)"
        :disliked="identity.isDislikedComment(item.comment.id)"
        :has-child="Boolean(item.children.length)"
        :is-child="isChildList"
        :is-reply="replyPid === item.comment.id"
        :hidden-avatar="hiddenAvatar"
        :hidden-ua="hiddenUa"
        :plain-vote="plainVote"
        @vote="handleVoteComment"
        @delete="handleDeleteComment"
        @reply="handleReplyComment"
        @cancel-reply="handleCancelReply"
      >
        <template #reply>
          <slot name="reply" :comment="item.comment" :is-child="false" />
        </template>
        <template #children v-if="item.children.length">
          <CommentList
            :comments="buildCommentTree(item.children)"
            :is-child-list="true"
            :hidden-avatar="hiddenAvatar"
            :hidden-ua="hiddenUa"
            :plain-vote="plainVote"
            :reply-pid="replyPid"
            @delete="handleDeleteComment"
            @reply="handleReplyComment"
            @cancel-reply="handleCancelReply"
          >
            <template #reply>
              <slot name="reply" :comment="item.comment" :is-child="true"></slot>
            </template>
          </CommentList>
        </template>
      </CommentItem>
    </transition-group>
  </ul>
</template>

<script setup lang="ts">
  import { CommentTreeItem } from '/@/stores/comment'
  import { Comment } from '/@/interfaces/comment'
  import { CommentEvents } from '../helper'
  import { useEnhancer } from '/@/app/enhancer'
  import { useStores } from '/@/stores'
  import { LanguageKey } from '/@/language'
  import CommentItem from './item.vue'
  import CommentList from './list.vue'

  interface Props {
    comments: CommentTreeItem[]
    replyPid: number
    isChildList?: boolean
    hiddenAvatar?: boolean
    hiddenUa?: boolean
    plainVote?: boolean
  }

  withDefaults(defineProps<Props>(), {
    isChildList: false,
    hiddenAvatar: false,
    hiddenUa: false,
    plainVote: false
  })

  const emits = defineEmits([CommentEvents.Reply, CommentEvents.Delete, CommentEvents.CancelReply])
  const { i18n } = useEnhancer()
  const { comment: commentStore, identity } = useStores()

  const handleReplyComment = (commentID: number) => {
    emits(CommentEvents.Reply, commentID)
  }

  const handleCancelReply = (commentID: number) => {
    emits(CommentEvents.CancelReply, commentID)
  }

  const handleDeleteComment = (commentID: number) => {
    emits(CommentEvents.Delete, commentID)
  }

  const handleVoteComment = async (commentID: number, isLike: boolean) => {
    if (isLike && identity.isLikedComment(commentID)) {
      return false
    }
    if (!isLike && identity.isDislikedComment(commentID)) {
      return false
    }
    try {
      await commentStore.postCommentVote(commentID, isLike ? 1 : -1)
      isLike ? identity.likeComment(commentID) : identity.dislikeComment(commentID)
    } catch (error) {
      const message = i18n.t(LanguageKey.POST_ACTION_ERROR)
      console.warn(message, error)
      alert(message)
    }
  }

  const buildCommentTree = (comments: Comment[]): Array<CommentTreeItem> => {
    return comments.map((comment) => ({
      comment,
      children: []
    }))
  }
</script>

<style lang="scss" scoped>
  .comment-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    // &.root {
    // }

    &.child {
      margin-top: $gap;
    }
  }
</style>
