<template>
  <span class="location">
    <span v-if="emojiText" class="emoji">{{ emojiText }}</span>
    <i v-else class="iconfont icon-earth"></i>
    <span>{{ countryText }}</span>
    <span class="separator">•</span>
    <span>{{ cityText }}</span>
  </span>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { IPLocation } from '/@/interfaces/comment'
  import { countryCodeToEmoji } from '/@/transforms/emoji'

  const municipalitys: string[] = ['Shanghai', 'Beijing', 'Tianjin', 'Chongqing', 'Chungking']

  const props = defineProps<{
    location: IPLocation
  }>()
  const countryText = computed(() => props.location.country_code || props.location.country)
  const emojiText = computed(() => countryCodeToEmoji(props.location.country_code))
  const cityText = computed(() => {
    if (props.location.country_code === 'CN') {
      if (municipalitys.includes(props.location.region)) {
        return props.location.region
      }
    }
    return props.location.city
  })
</script>

<style lang="scss" scoped>
  .location {
    display: inline-flex;
    align-items: center;
    &:hover {
      .emoji {
        color: $text;
      }
    }

    .iconfont {
      margin-right: $xs-gap;
    }

    .emoji {
      margin-right: $xs-gap;
      font-size: 130%;
    }

    .separator {
      margin: 0 3px;
    }
  }
</style>
