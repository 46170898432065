import { Pinia } from 'pinia'
import { useAnnouncementStore } from './announcement'
import {
  useArticleListStore,
  useArticleDetailStore,
  useHottestArticleListStore,
  useFeaturedArticleListStore,
  useLatestArticleListStore
} from './article'
import { useAdminInfoStore, useAppOptionStore } from './basic'
import { useCategoryStore } from './category'
import { useIdentityStore } from './identity'
import { useTagStore } from './tag'
import { useCommentStore } from './comment'
import { useNodepressStatisticStore } from './statistic'
import { useArticleCalendarStore } from './calendar'
import { useArchiveStore } from './archive'

export const useStores = (pinia?: Pinia) => ({
  hottestArticleList: useHottestArticleListStore(pinia),
  featuredArticleList: useFeaturedArticleListStore(pinia),
  latestArticleList: useLatestArticleListStore(pinia),

  announcement: useAnnouncementStore(pinia), // 公告

  articleList: useArticleListStore(pinia), // 文章列表
  articleDetail: useArticleDetailStore(pinia), // 文章详情

  category: useCategoryStore(pinia), // 分类
  tag: useTagStore(pinia), // 标签
  comment: useCommentStore(pinia), // 评论
  archive: useArchiveStore(pinia), // 归档
  identity: useIdentityStore(pinia), // 缓存信息（评论，点赞）
  adminInfo: useAdminInfoStore(pinia), // 个人信息
  appOption: useAppOptionStore(pinia), // 站点信息

  nodepressStatistic: useNodepressStatisticStore(pinia),

  articleCalendar: useArticleCalendarStore(pinia)
})
