import { defineComponent } from 'vue'
import { useGlobalState } from '/@/app/state'

export const DesktopOnly = defineComponent({
  name: 'DesktopOnly',
  setup(_, context) {
    const globalState = useGlobalState()
    return () => !globalState.userAgent.isMobile && context.slots.default?.()
  }
})
