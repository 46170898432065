<template>
  <li class="flower-item" :style="styles" :class="{ playing: state.playing }">
    <Webfont bolder>{{ options.text }}</Webfont>
  </li>
</template>

<script setup lang="ts">
  import { reactive, computed, nextTick, onMounted, onBeforeMount } from 'vue'
  import Webfont from '/@/components/common/webfont.vue'
  import { Event, IFlower } from './helper'

  const props = defineProps<{
    options: IFlower
    zIndex: number
  }>()

  const emits = defineEmits([Event.Ended])

  const state = reactive({
    delay: 1,
    timer: null as number | null,
    playing: false
  })

  const styles = computed(() => ({
    top: props.options.y + 'px',
    left: props.options.x + 'px',
    'z-index': 99999 + props.zIndex
  }))

  const startAnimation = () => {
    nextTick(() => {
      // 开始动画
      setTimeout(() => {
        state.playing = true
      })

      // 结束动画
      state.timer = window.setTimeout(() => {
        state.playing = false
        nextTick(() => {
          emits(Event.Ended, props.options.id)
        })
      }, state.delay * 1000)
    })
  }

  startAnimation()
  onMounted(startAnimation)
  onBeforeMount(() => {
    if (state.timer) {
      clearTimeout(state.timer)
      state.timer = null
    }
  })
</script>

<style lang="scss" scoped>
  .flower-item {
    opacity: 1;
    position: fixed;
    display: block;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.5s;
    color: $primary;
    font-size: $font-size-h3;
    white-space: nowrap;

    @keyframes flower-playing {
      0% {
        transform: translate3d(-50%, -50%, 0);
      }
      80% {
        transform: translate3d(-50%, -400%, 0);
        opacity: 0.6;
      }
      100% {
        transform: translate3d(-50%, -500%, 0);
        opacity: 0;
      }
    }

    &.playing {
      opacity: 0.8;
      animation: flower-playing 1s ease 0s forwards;
    }
  }
</style>
