import { isDev } from '/@/environment'

export const DEFAULT_DELAY = 468
export const IMAGE_SHARE_LONG_ARTICLE_THRESHOLD = 6688
export const RENDER_LONG_ARTICLE_THRESHOLD = 16688

export const PRIMARY_COLOR = '#0088f5'
export const GA_MEASUREMENT_ID = 'UA-84887611-3'
export const ADSENSE_CLIENT_ID = 'ca-pub-4710915636313788'

export const META = Object.freeze({
  title: 'zhangwurui.cn',
  zh_sub_title: '用文字记录生活，留下美好瞬间',
  en_sub_title: 'Words etch cherished moments.',
  zh_description: '红叶舞秋风 落日染天空 清泉润芳草 诗意绕心中',
  en_description:
    'Autumn wind dances with red leaves, Sunset paints the sky, Clear spring nourishes the fragrant grass, Poetry lingers in the heart.',
  url: 'https://zhangwurui.cn',
  domain: 'zhangwurui.cn',
  author: 'Superficial'
})

export const REDIS = {
  host: isDev ? '47.115.225.243' : 'redis',
  port: isDev ? 5621 : 6379
}

export const THIRD_IDS = Object.freeze({
  YOUTUBE_CHANNEL_ID: 'UCoL-j6T28PLSJ2U6ZdONS0w',
  MUSIC_163_BGM_ALBUM_ID: '767775603',
  GITHUB_USER_ID: 'surmon-china',
  TWITTER_USER_ID: 'surmon7788',
  INSTAGRAM_USERNAME: 'surmon666',
  INSTAGRAM_FB_ID: '17841405600281893'
})

export const VALUABLE_LINKS = Object.freeze({
  RSS: '/rss.xml',
  SITE_MAP: '/sitemap.xml',

  UPTIME_STATUS: 'https://stats.uptimerobot.com/Q2k7OTXxJN',
  GITHUB_SURMON_ME: 'https://github.com/surmon-china/surmon.me',
  GITHUB_NODEPRESS: 'https://github.com/surmon-china/nodepress',
  GITHUB_SURMON_ME_NATIVE: 'https://github.com/surmon-china/surmon.me.native',
  GITHUB_BLOG_STAR_LIST: 'https://github.com/stars/surmon-china/lists/surmon-me',
  GITHUB: 'https://github.com/surmon-china',
  MARKDOWN: 'https://daringfireball.net/projects/markdown/',
  MUSIC_163: `https://music.163.com/#/playlist?id=${THIRD_IDS.MUSIC_163_BGM_ALBUM_ID}`,
  DOUBAN: 'https://www.douban.com/people/nocower',
  DOUBAN_MOVIE: 'https://m.douban.com/people/56647958/movie_charts'
})

export const IDENTITIES = Object.freeze({
  GOOGLE_ANALYTICS_TRACKING_ID: 'UA-84887611-3',
  GOOGLE_ADSENSE_PUBLISHER_ID: 'ca-pub-4710915636313788',
  YOUTUBE_CHANNEL_ID: 'UCoL-j6T28PLSJ2U6ZdONS0w',
  MUSIC_163_BGM_ALBUM_ID: '767775603',
  DOUBAN_USER_ID: '56647958',
  GITHUB_USER_NAME: 'surmon-china',
  TWITTER_USER_NAME: 'surmon7788',
  INSTAGRAM_USERNAME: 'surmon666',
  INSTAGRAM_FB_ID: '17841405600281893',
  BTC_ADDRESS: 'bc1qhpdu03tnexkj4xsm3lqzyjdddz6z0rj2n7fsze',
  ETH_ADDRESS: '0xaD556974D449126efdeF23f4FF581861C301cB77',
  OPENSEA_ETH: '0xaD556974D449126efdeF23f4FF581861C301cB77'
})
