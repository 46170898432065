<template>
  <div class="desktop-main">
    <Background />
    <Wallflower />
    <HeaderView />
    <ClientOnly>
      <Popup
        :visible="switcher.feedback"
        :mask-close="false"
        :scroll-close="false"
        @close="handleFeedbackModalClose"
      >
        <feedback @close="handleFeedbackModalClose" />
      </Popup>
    </ClientOnly>
    <template v-if="!globalState.layoutColumn.value.isFull">
      <Share class="main-share" disabled-image-share />
      <Toolbox />
      <ClientOnly>
        <MusicPlayerHandle />
      </ClientOnly>
    </template>
    <main
      :id="MAIN_ELEMENT_ID"
      class="main-container"
      :class="{ 'full-page': globalState.layoutColumn.value.isFull }"
    >
      <NavView class="nav-view" v-if="globalState.layoutColumn.value.isNormal" />
      <AsideView class="aside-view" v-if="globalState.layoutColumn.value.isNormal" />
      <div
        :id="MAIN_CONTENT_ELEMENT_ID"
        class="main-view"
        :class="{
          'layout-normal': globalState.layoutColumn.value.isNormal,
          'layout-wide': globalState.layoutColumn.value.isWide,
          'layout-full': globalState.layoutColumn.value.isFull
        }"
      >
        <router-view v-slot="{ Component, route: r }">
          <div class="router-view">
            <transition name="page" mode="out-in" @before-enter="handlePageTransitionDone">
              <component :is="Component" :key="r.name" />
            </transition>
          </div>
        </router-view>
      </div>
    </main>
    <FooterView />
  </div>
</template>

<script setup lang="ts">
  import Background from '/@/components/widget/background.vue'
  import Wallflower from '/@/components/widget/wallflower/graden.vue'
  import Share from '/@/components/widget/share.vue'
  import HeaderView from './header.vue'
  import FooterView from './footer.vue'
  import NavView from './nav.vue'
  import AsideView from './aside/index.vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { getLayoutByRouteMeta } from '/@/transforms/layout'
  import MusicPlayerHandle from '/@/components/widget/music-player/handle.vue'
  import Toolbox from '/@/components/widget/toolbox.vue'
  import Feedback from '/@/components/widget/feedback.vue'
  import { onMounted } from 'vue'
  import { useMusic } from '/@/composables/music'
  import logger from '/@/utils/logger'
  import { MAIN_ELEMENT_ID, MAIN_CONTENT_ELEMENT_ID } from '/@/constants/anchor'

  const { route, globalState } = useEnhancer()

  const { switcher, layoutColumn } = globalState
  const handlePageTransitionDone = () => {
    globalState.setLayoutColumn(getLayoutByRouteMeta(route.meta))
  }

  const handleFeedbackModalClose = () => {
    globalState.toggleSwitcher('feedback', false)
  }

  onMounted(() => {
    useMusic()
      .init()
      .catch((error) => {
        logger.failure('player init failed!', error)
      })
  })
</script>

<style lang="scss" scoped>
  .desktop-main {
    padding-top: $header-height + $lg-gap;

    @media screen and (max-width: 1200px) {
      #theme,
      #language,
      .main-share {
        display: none !important;
      }
    }

    .main-share {
      position: fixed;
      top: 12%;
      left: 0;
      height: auto;
      max-width: 4rem;
      display: flex;
      flex-direction: column;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }

      ::v-deep(.share-ejector) {
        $height: 2.1em;
        width: 3rem;
        height: $height;
        line-height: $height;
        font-size: $font-size-h4;
        border-top-right-radius: $xs-radius;
        border-bottom-right-radius: $xs-radius;
        transition: width $transition-time-fast;
        &:hover {
          width: 120%;
        }
      }
    }

    .main-container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: $container-width;
      &.full-page {
        width: 100%;
        min-height: calc(100vh - 5.2rem - 6em - 1em - 1em);
      }

      .nav-view {
        order: 1;
        flex-shrink: 0;
        margin-right: $lg-gap;
      }

      .aside-view {
        order: 3;
        flex-shrink: 0;
        margin-left: $lg-gap;
      }

      .main-view {
        order: 2;
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        transition: none;

        &:-moz-full-screen {
          overflow-y: auto;
        }

        &:-webkit-full-screen {
          overflow-y: auto;
        }

        &:fullscreen {
          overflow-y: auto;
        }

        // &.layout-normal {
        //   flex-grow: 1;
        // }

        &.layout-wide {
          flex-grow: unset;
          width: 100%;
          margin: 0;
        }

        &.layout-full {
          flex-grow: unset;
          width: 100%;
          margin: -$lg-gap 0;
        }
      }
    }

    .footer-view {
      margin-top: $lg-gap;
    }
  }
</style>
