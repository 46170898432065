<template>
  <footer class="footer">
    <div class="footer-content">©{{ year }}&nbsp;&nbsp;{{ title }}</div>
  </footer>
</template>

<script setup lang="ts">
  import { META } from '/@/config/app.config'

  const title = META.title
  const year = new Date().getFullYear()
</script>

<style lang="scss" scoped>
  .footer {
    margin: 0;
    @include common-bg-module();

    .footer-content {
      width: 100%;
      height: $mobile-footer-height;
      line-height: $mobile-footer-height;
      font-size: $font-size-h6;
      text-align: center;
      text-transform: uppercase;
    }
  }
</style>
