export enum Event {
  Ended = 'ended'
}

export interface IFlower {
  id: number
  x: number
  y: number
  text: string
}
