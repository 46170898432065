import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useGlobalState } from './state'
import { useI18n } from '/@/composables/i18n'
import { useHead, useSeoMeta } from '/@/composables/head'
import { Language } from '/@/language'
import { useAppOptionStore } from '/@/stores/basic'
import { Theme, useTheme } from '/@/composables/theme'
import { useCDNDomain } from './context'

export const useEnhancer = () => {
  const route = useRoute()
  const router = useRouter()
  const i18n = useI18n()
  const theme = useTheme()
  const globalState = useGlobalState()
  const appOptionState = useAppOptionStore()

  const adConfig = computed(() => appOptionState.adConfig)
  const isMobile = computed(() => globalState.userAgent.isMobile)
  const isDarkTheme = computed(() => theme.theme.value === Theme.Dark)
  const isZhLang = computed(() => i18n.language.value === Language.Chinese)

  return {
    route,
    router,
    globalState,
    i18n,
    theme,
    head: useHead,
    seoMeta: useSeoMeta,
    cdnDomain: useCDNDomain(),

    adConfig,
    isMobile,
    isDarkTheme,
    isZhLang
  }
}
