<template>
  <div class="index-page">
    <Carrousel class="carrousel" :articles="articleListStore.data" :fetching="articleListStore.fetching" />
    <Announcement :announcements="announcementStore.data" :fetching="announcementStore.fetching" />
    <ArticleList
      :mammon="false"
      :fetching="articleListStore.fetching"
      :articles="articleListStore.data"
      :pagination="articleListStore.pagination"
      @loadmore="loadmoreArticles"
    />
  </div>
</template>

<script setup lang="ts">
  import { useStores } from '/@/stores'
  import { useEnhancer } from '/@/app/enhancer'
  import { META } from '/@/config/app.config'
  import { LanguageKey } from '/@/language'
  import { onClient, useUniversalFetch } from '/@/universal'
  import { scrollToNextScreen } from '/@/utils/scroller'
  import Carrousel from './carrousel.vue'
  import ArticleList from '/@/components/flow/desktop/list.vue'
  import Announcement from './announcement.vue'

  const { seoMeta, i18n: _i18n, isZhLang } = useEnhancer()

  const { articleList: articleListStore, announcement: announcementStore, appOption } = useStores()

  seoMeta(() => ({
    title: `${META.title} - ${_i18n.t(LanguageKey.APP_SLOGAN)!.replaceAll('，', ' ')}`,
    description: isZhLang.value ? META.zh_description : META.en_description,
    keywords: appOption.data?.keywords.join(',')
  }))

  const loadmoreArticles = async () => {
    const targetPage = articleListStore.pagination!.current_page + 1
    await articleListStore.fetch({ page: targetPage })
    if (targetPage > 1) {
      onClient(scrollToNextScreen)
    }
  }

  useUniversalFetch(() => {
    return Promise.all([articleListStore.fetch()])
  })
</script>

<style lang="scss" scoped>
  .index-page {
    .carrousel,
    .twitter {
      margin-bottom: $lg-gap;
    }
  }
</style>
